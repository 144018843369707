<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
      </div>
      <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title d-flex flex-row justify-content-between align-items-center w-100">
            <h4 class="mr-1"><i class="fas fa-receipt"></i> โพยหวย</h4>
          </div>
        </div>
        <div class="border border-dark bg-white shadow-sm rounded mb-2" style="overflow: hidden">
          <div class="bg-dark text-white py-1 px-2"><i class="sn-icon sn-icon--horoscope2"></i> ยอดแทงวันนี้</div>
          <div class="bg-light py-1 px-2">
            <div class="row px-3">
              <div class="col-6 col-sm-6 pl-0 pr-1">
                <div class="mr-1 mt-1 py-1 text-center w-100 rounded bg-primary text-white">
                  <small>ยอดรวม</small>
                  <h5 class="font-weight-normal thb">
                    ฿
                    {{
                      numberWithCommas(
                      list
                        .filter(e => (e.isToday && (Number(e.status) != 2)))
                        .map(e => e.price)
                        .reduce((a, b) => Number(a) + Number(b), 0))
                    }}
                  </h5>
                </div>
              </div>
              <div class="col-3 col-sm-3 px-0">
                <div class="m-1 py-1 text-center border-left w-100">
                  <small>ออกผลแล้ว</small>
                  <h5 class="font-weight-light text-success">
                    {{ list.filter(x => ((Number(x.status) != 0) && x.isToday)).map(e => e.status).length }}
                  </h5>
                </div>
              </div>
              <div class="col-3 col-sm-3 px-0">
                <div class="m-1 py-1 text-center border-left w-100">
                  <small>ยังไม่ออกผล</small>
                  <h5 class="font-weight-light text-primary">
                    {{ list.filter(x => ((Number(x.status) == 0) && x.isToday)).map(e => e.status).length }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border border-secondary bg-white shadow-sm rounded mb-5">
          <div class="row m-0 poy-all">
    <!------------------------- poy menu ---------------------------->
            <div class="col-12 col-sm-12 col-md-4 p-0 bg-dark">
            <div class="dropdown bootstrap-select form-control ">
              <!-- <select class="selectpicker form-control" data-style="btn-dark" onchange="select_type(this.value)" tabindex="-98">
                <option value="" selected="">หวยทั้งหมด - LOTTOVIP.COMPANY</option>
                <option value="GOVERNMENT">หวยรัฐบาล</option>
                <option value="STOCK">หวยหุ้น</option>
                <option value="YEEKEE">จั่กี VIP</option>
              </select> -->
              <button type="button" class="btn dropdown-toggle bs-placeholder btn-dark" data-toggle="dropdown" role="button" title="หวยทั้งหมด - LOTTOVIP.COMPANY" aria-expanded="true">
                <div class="filter-option"><div class="filter-option-inner">{{ options[selectedHuay].text }}</div></div>&nbsp;<span class="bs-caret"><span class="caret"></span></span>
              </button>
              <div class="dropdown-menu" role="combobox" x-placement="bottom-start" style="max-height: 326px; overflow: hidden; min-height: 116px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 38px, 0px);">
                <div class="inner show" role="listbox" aria-expanded="true" tabindex="-1" style="max-height: 308px; overflow-y: auto; min-height: 98px;">
                  <ul class="dropdown-menu inner show">
                    <!-- <li><a role="option" class="dropdown-item selected active" aria-disabled="false" tabindex="0" aria-selected="true"><span class=" bs-ok-default check-mark"></span><span class="text">หวยทั้งหมด</span></a></li>
                    <li><a role="option" class="dropdown-item" aria-disabled="false" tabindex="0" aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">หวยไทย</span></a></li>
                    <li><a role="option" class="dropdown-item" aria-disabled="false" tabindex="0" aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">หวยหุ้น</span></a></li>
                     -->
                      <li v-for="(item, index) in options" :key="index">
                        <a v-on:click="selectedHuay=index;" role="option" class="dropdown-item" aria-disabled="false" tabindex="0" aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">{{ item.text }}</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
              <div class="col-3 col-sm-3 col-md-2 p-0">
                  <a v-on:click="selectedPoy=-1;" href="#" class="btn btn-primary btn-sm btn-block h-100 btn-poy" v-bind:class="{ active: getTapPoyActive(-1)}" data-id="poy-today">
                      <i class="fas fa-calendar-week d-none"></i>
                      <div class="text-center"><span>โพย</span></div>
                  </a>
              </div>
              <div class="col-3 col-sm-3 col-md-2 p-0">
                  <a v-on:click="selectedPoy=0;" href="#" class="btn btn-warning btn-sm btn-block h-100 btn-poy" v-bind:class="{ active: getTapPoyActive(0)}" data-id="poy-notyet">
                      <i class="fas fa-times-circle d-none"></i>
                      <div class="text-center"><span>โพยหวย</span><span>ที่ยังไม่ออกผล</span></div>
                  </a>
              </div>
              <div class="col-3 col-sm-3 col-md-2 p-0">
                  <a v-on:click="selectedPoy=3;" href="#" class="btn btn-success btn-sm btn-block h-100 btn-poy" v-bind:class="{ active: (getTapPoyActive(1) || getTapPoyActive(3))   }" data-id="poy-success">
                      <i class="fas fa-check-circle d-none"></i>
                      <div class="text-center"><span>โพยหวย</span><span>ออกผลแล้ว</span></div>
                  </a>
              </div>
              <div class="col-3 col-sm-3 col-md-2 p-0">
                  <a v-on:click="selectedPoy=2;" href="#" class="btn btn-secondary btn-sm btn-block h-100 btn-poy" v-bind:class="{ active: getTapPoyActive(2)}" data-id="poy-history">
                      <i class="fas fa-history d-none"></i>
                      <div class="text-center"><span>โพย</span><span>ก่อนหน้า</span></div>
                  </a>
              </div>
          </div>
          <div class="col-12 p-0 table-active poy-content active" v-if="display_list.length > 0">
            <template v-for="(item, indexX) in display_list" >
            <div class="poy-list" v-if="((indexX < (currentPage * perPage)) && (indexX >= ((currentPage - 1) * perPage)))" :key="item.id">
              <div>
              <div class="poy-list-head">
                <small>โพย เลขที่{{ indexX }}</small>
                <span>#{{ item.TicketId }}</span>
                <div class="poy-status notyet" v-if="item.status == 0 || item.status == 1">
                  {{ item.statusName }}
                </div>
                <div class="poy-status" :class=" item.status == 2  ? 'lost' : 'success'" v-else-if="item.status == 2">
                  {{ item.statusName  }}
                </div>
                 <div class="poy-status" :class=" item.IsWinPrice  ? 'success' : 'lost'" v-else>
                  {{ item.IsWinPrice ? 'ถูกรางวัล' : 'ไม่ถูกรางวัล' }}
                </div>
              </div>
              <div class="poy-list-content">
                <div class="row">
                  <div class="col-7 m-0 pl-2 pr-1">
                    <div class="poy-type">
                      <span class="text-wrap ">{{ item.title }}</span
                      ><br />
                      <small>วันที่ : {{ getDate(item.dateBetting) }}</small>
                    </div>
                  </div>
                  <div class="col m-0 pl-1 pr-3 pb-1 border-left">
                    <div class="d-flex justify-content-between">
                    </div>
                    <div class="d-flex justify-content-between">
                      <small>เงินเดิมพัน</small>
                      <span class=""
                        ><span class="thb text-primary">฿ {{ numberWithCommas(item.price) }}</span></span
                      >
                    </div>
                    <div class="d-flex justify-content-between">
                      <small>ผลแพ้/ชนะ</small>
                      <span class=""
                        ><span class="thb">฿ {{ numberWithCommas(item.WinAmount) }}</span></span
                      >
                    </div>
                  </div>
                  <div class="col-12 border-top m-0 pt-1">
                    <span class="badge"><i class="far fa-calendar-alt"></i> {{ getDate(item.dateBetting) }}</span>
                    <span class="badge"><i class="far fa-clock"></i> {{ getTime(item.dateBetting) }}</span>
                    <router-link
                      :to="`/member/poy/result/${item.TicketId}`"
                      class="btn btn-secondary btn-sm py-0 px-1 float-right"
                      >รายละเอียด <i class="fas fa-search"></i
                    ></router-link>
                  </div>
                </div>
              </div>
              </div>
            </div>
            </template>
              <b-pagination
                align="center"
                v-model="currentPage"
                :total-rows="display_list.length"
                :per-page="perPage"
                aria-controls="my-table">
              </b-pagination>
          </div>
          <div v-else class="col-12 p-0 table-primary poy-content active">
            <div class="text-center">ไม่มีรายการ</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { listAllBetHistoryService } from '@/services'
export default {
  async created () {
    this.setLoading(true)
    const resp = await listAllBetHistoryService()
    if (resp.status_code === 'SUCCESS') {
      const data = resp.data.map(e => {
        return {
          ...e,
          numberbet: [],
          // numberbet: e.numberbet,
          WinAmount: parseInt(e.WinAmount),
          IsWinPrice: JSON.parse(e.IsWinPrice),
          isToday: JSON.parse(e.isToday),
          dateBetting: moment(e.dateBetting)
        }
      })
      this.list = data.filter(v => (Number(v.group_id) !== Number(3) && Number(v.group_id) !== Number(4)))
      this.reloadDataList()
      // -1, 0, 3, 2
      this.setLoading(false)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    getDate (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    getTime (date) {
      return moment(date).format('HH:mm:ss')
    },
    getTapPoyActive (data) {
      return (Number(this.selectedPoy) === Number(data))
    },
    getCanDisplay (group_id, status, index, isToday) {
      let result = true
      /* check type huay */
      if (Number(this.options[this.selectedHuay].gIndex) === -1) {
        result &= true
      } else if (Number(this.options[this.selectedHuay].gIndex) === Number(group_id)) {
        result &= true
      } else {
        result &= false
      }
      /* check status ticket */
      if (Number(this.selectedPoy) === 2) {
        result &= true
      } else if ((Number(this.selectedPoy) === -1) && isToday) {
        result &= true
      } else if ((Number(this.selectedPoy) === 0) && isToday && (Number(status) === 2)) {
        result &= true
      } else if ((Number(this.selectedPoy) === Number(status)) && isToday) {
        result &= true
      } else {
        result &= false
      }
      return result
    },
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    reloadDataList () {
      this.currentPage = 1
      this.display_list = this.list.filter((item, indexItem) => this.getCanDisplay(item.group_id, item.status, indexItem, item.isToday))
    }
  },
  data () {
    return {
      perPage: 30,
      currentPage: 1,
      list: [],
      data_list: [],
      display_list: [],
      selectedHuay: 0,
      selectedPoy: -1,
      options: [
        { gIndex: -1, text: 'หวยทั้งหมด' },
        { gIndex: 0, text: 'หวยไทย' },
        { gIndex: 1, text: 'ยี่กี' },
        { gIndex: 2, text: 'หวยหุ้น' }

      ]
    }
  },
  watch: {
    selectedHuay: function (val) {
      this.reloadDataList()
    },
    selectedPoy: function (val) {
      this.reloadDataList()
    }
  }
}
</script>

<style>
</style>
